@font-face {
  font-display: block;
  font-family: 'SimpWillIcons';
  font-style: normal;
  font-weight: normal;
  src: url('SimpWillIcons.eot?6pgmua');
  src: url('SimpWillIcons.eot?6pgmua#iefix') format('embedded-opentype'),
    url('SimpWillIcons.ttf?6pgmua') format('truetype'),
    url('SimpWillIcons.woff?6pgmua') format('woff'),
    url('SimpWillIcons.svg?6pgmua#SimpWillIcons') format('svg');
}

[class^='icon-'],
[class*=' icon-'] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'SimpWillIcons' !important;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-style: normal;
  font-variant: normal;
  font-weight: normal;
  line-height: 1;
  speak: never;
  text-transform: none;
}

.icon-arrow::before {
  content: '\e933';
}

.icon-arrow-bold::before {
  content: '\e932';
}

.icon-arrow-down::before {
  content: '\e901';
}

.icon-arrow-left::before {
  content: '\e902';
}

.icon-arrow-right::before {
  content: '\e903';
}

.icon-arrow-up::before {
  content: '\e904';
}

.icon-arrow-up-bold::before {
  content: '\e905';
}

.icon-car::before {
  content: '\e906';
}

.icon-check::before {
  content: '\e907';
}

.icon-checki-in-circle::before {
  content: '\e908';
}

.icon-check-paper::before {
  content: '\e909';
}

.icon-child::before {
  content: '\e90a';
}

.icon-close::before {
  content: '\e90b';
}

.icon-collaboration::before {
  content: '\e90c';
}

.icon-collection::before {
  content: '\e90d';
}

.icon-cross::before {
  content: '\e90e';
}

.icon-cross-bold::before {
  content: '\e90f';
}

.icon-document::before {
  content: '\e910';
}

.icon-coronavirus::before {
  content: '\e931';
}

.icon-download::before {
  content: '\e911';
}

.icon-email::before {
  content: '\e912';
}

.icon-eye::before {
  content: '\e913';
}

.icon-facebook::before {
  content: '\e915';
}

.icon-form-doc::before {
  content: '\e916';
}

.icon-house::before {
  content: '\e917';
}

.icon-information::before {
  content: '\e918';
}

.icon-like::before {
  content: '\e91a';
}

.icon-location::before {
  content: '\e91c';
}

.icon-lock::before {
  content: '\e91d';
}

.icon-menu::before {
  content: '\e91e';
}

.icon-partners::before {
  content: '\e91f';
}

.icon-paw::before {
  content: '\e920';
}

.icon-pen::before {
  content: '\e921';
}

.icon-phone::before {
  content: '\e922';
}

.icon-picture::before {
  content: '\e923';
}

.icon-plan::before {
  content: '\e924';
}

.icon-plus::before {
  content: '\e925';
}

.icon-question::before {
  content: '\e926';
}

.icon-report::before {
  content: '\e927';
}

.icon-search::before {
  content: '\e914';
}

.icon-sterling::before {
  content: '\e929';
}

.icon-update::before {
  content: '\e92b';
}

.icon-user::before {
  content: '\e92c';
}

.icon-wallet::before {
  content: '\e92d';
}

.icon-watch::before {
  content: '\e92e';
}

.icon-wide::before {
  content: '\e92f';
}

.icon-write-will::before {
  content: '\e930';
}
